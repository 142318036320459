import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import {SectionPage, Container, SectionDescription, SectionHeading} from "../../components/Section"
import ArrowIcon from "../../components/Icons/ArrowIcon"
import Video from "../../components/Video"

const ContentGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  margin: 0 -10px;
  @media (min-width: 992px) {
    text-align: left;
    margin: 0 -30px;
  }
  text-align: left;
  & a {
    color: #ed602b;
    font-size: 16px;
    font-weight: 800;
    text-decoration: none;
    & svg {
      width: 30px;
      margin-left: 5px;
      transition: ease 0.3s;
    }
    &:hover {
      cursor: pointer;
      & svg {
        margin-left: 10px;
        transition: ease 0.3s;
      }
    }
  }
`
const ContentLeftGrid = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  margin-bottom: 30px;
  @media (min-width: 768px) {
    margin-bottom: 0;
    flex: 0 0 60%;
    max-width: 60%;
  }
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 30px;
  }
`
const ContentRightGrid = styled.div`
  position: relative;
  width: 100%;
  padding: 0 10px;
  @media (min-width: 768px) {
    flex: 0 0 40%;
    max-width: 40%;
  }
  @media (min-width: 992px) {
    flex: 0 0 50%;
    max-width: 50%;
    padding: 0 30px;
  }
`
class OverviewSection extends React.Component {
  render() {
    const { data } = this.props
    return (
      <SectionPage
        ept="80px"
        epb="80px"
        xpt="60px"
        xpb="60px"
        pt="40px"
        pb="40px"
        bg="#fff"
      >
        <Container>
          <ContentGrid>
            <ContentLeftGrid>
              <SectionHeading>{data.title}</SectionHeading>
              <SectionDescription
                textAlign="left"
                dangerouslySetInnerHTML={{
                  __html: data.description.childMarkdownRemark.html,
                }}
              />
              <Link to="/about/">Know More <ArrowIcon fill="#ED602B" /></Link>
            </ContentLeftGrid>
            <ContentRightGrid>
              <Video />
            </ContentRightGrid>
          </ContentGrid>
        </Container>
      </SectionPage>
    )
  }
}

export default OverviewSection

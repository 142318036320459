import React from "react"
import {
  Container,
  SectionPage,
  SectionHeading,
} from "../../components/Section"
import styled from "styled-components";
import { Link } from "gatsby";

const CategoryWrap = styled.div`
  display: grid;
  grid-column-gap: 1rem;
  grid-row-gap: 1rem;
  @media (min-width: 576px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
`

const CategoryItem = styled.div`
  position:relative;
`;

const CategoryCard = styled.div`
  position:relative;
 
`;

const CategoryFigure = styled.div`  
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 15px;
  overflow: hidden;
  margin-bottom:10px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
`;

const CategoryName = styled.div`
  position: relative;
  border-radius: 15px;
  background: #e8f3f9;
  text-align: center;
  margin: 0px auto;
  padding: 15px;
  @media (min-width: 992px) {    
    padding: 20px 15px;
  }
  > a{
    color:#062C44;
    font-weight:600;
    font-size: 18px;
    line-height: 28px;
    @media (min-width: 992px) {     
      font-size: 20px;
      line-height: 30px;   
    }
    @media (min-width: 1200px) {      
      font-size: 24px;
      line-height: 34px;
    }
    &:hover{
      color:#FF3E00;
    }
  }
`;


const IntroSection = ({ data, imageData }) => {
  const imageMap = imageData.reduce((result, image) => {
    return {
      ...result,
      [image.node.title]: image.node.fluid.src
    }
  }, {});

  return (
    <SectionPage
      ept="80px"
      epb="80px"
      xpt="60px"
      xpb="60px"
      pt="40px"
      pb="40px"
      bg="#fff"
    >
      <Container>
        <SectionHeading textAlign="center" mb="20px">{data.title}</SectionHeading>
        <CategoryWrap>
          {data.features.map((category, i) => (
            <CategoryItem key={i}>
              <CategoryCard>
                <CategoryFigure><Link to={category.link + "/"}><img src={imageMap[`acc-${category.name}`]} alt={category.name} /></Link></CategoryFigure>
                <CategoryName><Link to={category.link + "/"}>{category.name}</Link></CategoryName>
              </CategoryCard>
              {/* <CategoryContent>
                <CategoryDesc>
                  <p>{category.description}</p>
                </CategoryDesc>
                <CategoryActions>
                  <Link to={category.link}>
                    <SecondaryButton text="View details" />
                  </Link>
                </CategoryActions>
              </CategoryContent> */}
            </CategoryItem>
          ))}
        </CategoryWrap>
      </Container>
    </SectionPage>
  )
}

export default IntroSection

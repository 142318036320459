import React, { useEffect } from "react"
import styled from "styled-components"
import { Container } from "../Section";
import { simplemaps_usmap_mapdata } from "./config";
import { graphql, useStaticQuery } from "gatsby";

const MapWrap = styled.div``

const Map = ({ stateName, mapImage, cities }) => {
  const stateData = useStaticQuery(graphql`
      query siteTitleQueryAndSiteTitleQuery {
        allContentfulStateLanding {
          edges {
            node {
              url
              abbreviation
            }
          }
        }
      }
    `)

  useEffect(() => {
    let mapData = { ...simplemaps_usmap_mapdata };
    stateData.allContentfulStateLanding.edges.map(({ node }) => {
      const { url, abbreviation } = node;
      if (mapData.state_specific[abbreviation]) {
        mapData.state_specific[abbreviation].url = url;
      }
    })
    window.simplemaps_usmap_mapdata = mapData;
    const usMapScript = document.createElement("script");
    usMapScript.src = "/usmap/index.js";
    document.body.append(usMapScript);

    loadMap();
  }, [])

  const loadMap = () => {
    setTimeout(() => {
      if (window.simplemaps_usmap) {
        window.simplemaps_usmap.load();
      } else {
        loadMap();
      }
    }, 100)
  }

  return (
    <Container maxWidth="1200px">
      <MapWrap>
        <div id="map" />
      </MapWrap>
    </Container>
  )
}

export default Map
